import React, { Dispatch, SetStateAction, Fragment } from 'react'
import { Link } from 'gatsby'
import * as BlockStyles from './Block.module.scss'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import Favourites from '../../Favourites/Favourites'
import { MULTISITE_COUNTIES } from '../../../lib/data/Sites'

interface BlockProps {
  id?: string | null
  image?: string | null
  uri: string
  primaryCategory?: string | null
  primaryCategoryURI?: string | null
  title?: string | null
  linkType?: string | null
  isSpotlight?: boolean
  spotlightText?: string
  isVisible?: boolean
  darkMode?: boolean
  isFavourite?: boolean
  setFetchTime?: Dispatch<SetStateAction<string>>
}

interface IConditionalLink {
  linkType?: string | null
  uri: string
  children: React.ReactChild
  className?: string
  title?: string | null
}

const Block = ({
  id,
  image,
  uri,
  primaryCategory,
  primaryCategoryURI,
  title,
  linkType,
  isSpotlight,
  spotlightText,
  darkMode = false,
  isFavourite,
  setFetchTime,
  isVisible
}: BlockProps) => {
  const ConditionalLink = ({
    linkType,
    uri,
    children,
    className,
    title
  }: IConditionalLink) => {
    if (linkType === 'internal') {
      const fixedUri = uri.replace('https://dev.muddystilettos.co.uk', '')
      return (
        <Link to={fixedUri} className={className} aria-label={title ?? ''}>
          {children}
        </Link>
      )
    }

    return (
      <OutboundLink href={uri} className={className}>
        {children}
      </OutboundLink>
    )
  }

  const Text = () => {
    return (
      <div className={BlockStyles.Text}>
        {primaryCategory && (
          <ConditionalLink
            linkType={linkType}
            uri={primaryCategoryURI ?? ''}
            className={BlockStyles.Category}
            title={title}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: primaryCategory ?? ''
              }}
            />
          </ConditionalLink>
        )}
        {title && (
          <p>
            <ConditionalLink linkType={linkType} uri={uri ?? ''} title={title}>
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </ConditionalLink>
          </p>
        )}
      </div>
    )
  }

  return (
    <div
      className={`${BlockStyles.Base} ${
        isSpotlight ? BlockStyles.HasSpotlight : ''
      } ${darkMode && BlockStyles.DarkMode}
      `}
      key={id}
    >
      {isFavourite && id && setFetchTime && (
        <div className={BlockStyles.Favourites}>
          <Favourites
            setFetchTime={setFetchTime}
            postId={id}
            siteId={
              (primaryCategory &&
                MULTISITE_COUNTIES.find(
                  c => c.shortName === primaryCategory
                )?.id.toString()) ??
              undefined
            }
          />
        </div>
      )}
      <div className={BlockStyles.Img}>
        {image && (
          <ConditionalLink linkType={linkType} uri={uri ?? ''} title={title}>
            <img src={image} alt={title || ''} />
          </ConditionalLink>
        )}
      </div>
      {isSpotlight ? (
        <>
          {spotlightText && spotlightText === 'bptlresults' && (
            <div className={BlockStyles.BPTLNumber}>
              {/* <span>Best Places to Live: {primaryCategory}</span> */}
              <p>#1</p>
            </div>
          )}
          <div className={BlockStyles.Shader}></div>
          <div className={BlockStyles.SpotlightOverlay}>
            {spotlightText && spotlightText !== 'bptlresults' && (
              <h2 className={isVisible ? BlockStyles.fadeOut : ''}>
                {spotlightText}
              </h2>
            )}
            <Text />
          </div>
        </>
      ) : (
        <Text />
      )}
    </div>
  )
}

export default Block
