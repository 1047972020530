import React, { useEffect, useState } from 'react'
import * as TopBannerStyles from './../TopBanner/TopBanner.module.scss'

const FooterBanner = () => {
  const [adition, setAdition] = useState<any>(null)
  useEffect(() => {
    if (adition && adition !== null) {
      // eslint-disable-next-line
    // @ts-ignore
      const qded = window.adition
      qded.srq.push(function (api: any) {
        api.renderSlot('BotLB')
      })
    }
  }, [adition])

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null

    const checkAdition = () => {
      if (typeof window !== 'undefined' && 'adition' in window) {
        if ('ready' in window.adition) {
          setAdition(window.adition)
          if (intervalId) clearInterval(intervalId) // Clear the interval if it has been set
        }
      }
    }

    intervalId = setInterval(checkAdition, 1000) // Check every second

    return () => {
      // Clean up on component unmount
      if (intervalId) clearInterval(intervalId)
    }
  }, [])

  return (
    <div className={TopBannerStyles.Wrapper}>
      <div id="BotLB"></div>
    </div>
  )
}

export default FooterBanner
