import React, { useRef } from 'react'
import Heading from '../../Blocks/heading/Heading'
import * as RecommendedArticlesStyles from './RecommendedArticles.module.scss'
import Block from '../../Blocks/block/Block'
import Slider from 'react-slick'
import '../../../../static/slick.min.css'
import '../../../../static/slick-theme.min.css'
import Arrow from '../../../assets/arrow-left.svg'
import { stripCounty } from '../../../lib/utils'

interface IRecommendedArticle {
  link?: string | null
  title?: string | null
  image?: string | null
  category?: string | null
  categoryURI?: string | null
}

interface RecommendedArticlesProps {
  recommendedArticles?: Array<IRecommendedArticle | null> | null
  isBestPlacestoLive?: boolean
  category?: string | null
  siteId?: number
}

const slickSettings = {
  dots: false,
  infinite: true,
  autoplay: false,
  slidesToShow: 4,
  initialSlide: 0,
  centerMode: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        swipeToSlide: true
      }
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        swipeToSlide: true
      }
    },
    {
      breakpoint: 420,
      settings: {
        slidesToShow: 1,
        swipeToSlide: true
      }
    }
  ]
}

const RecommendedArticles = ({
  recommendedArticles,
  isBestPlacestoLive,
  category,
  siteId
}: RecommendedArticlesProps) => {
  const recommendArticlesSlider = useRef<Slider | null>(null)

  const handleSliderPrev = () => {
    recommendArticlesSlider.current?.slickPrev()
  }

  const handleSliderNext = () => {
    recommendArticlesSlider.current?.slickNext()
  }

  const RecommendedArticleBlock = ({
    link,
    image,
    title,
    category,
    categoryURI
  }: IRecommendedArticle) => {
    return (
      <Block
        image={image}
        uri={stripCounty(link ?? '', siteId)}
        {...(!isBestPlacestoLive && {
          primaryCategory: category,
          primaryCategoryURI: categoryURI
        })}
        title={
          isBestPlacestoLive
            ? title?.replace('Best Places to Live:', '')
            : title
        }
      />
    )
  }

  return (
    <div className={RecommendedArticlesStyles.Base}>
      <Heading>
        {isBestPlacestoLive && category
          ? `See more in ${category?.replace('&amp;', '&')}`
          : `You may also like`}
      </Heading>
      {recommendedArticles && recommendedArticles.length > 3 ? (
        <>
          <Slider {...slickSettings} ref={recommendArticlesSlider}>
            {recommendedArticles &&
              recommendedArticles.map((post, index) =>
                post ? <RecommendedArticleBlock key={index} {...post} /> : null
              )}
          </Slider>
          <div className={RecommendedArticlesStyles.Pagination}>
            <div
              className={RecommendedArticlesStyles.Left}
              onClick={() => handleSliderPrev()}
            >
              <img src={Arrow} alt={`Arrow`} />
            </div>
            <div
              className={RecommendedArticlesStyles.Right}
              onClick={() => handleSliderNext()}
            >
              <img src={Arrow} alt={`Arrow`} />
            </div>
          </div>
        </>
      ) : (
        <div className={RecommendedArticlesStyles.RecommendedWrap}>
          {recommendedArticles &&
            recommendedArticles.map((post, index) =>
              post ? <RecommendedArticleBlock key={index} {...post} /> : null
            )}
        </div>
      )}
    </div>
  )
}

export default RecommendedArticles
