import React from 'react'
import { graphql } from 'gatsby'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Article from '../components/layoutComponents/article/Article'
import TopBanner from '../components/Banners/TopBanner/TopBanner'
import FooterBanner from '../components/Banners/FooterBanner/FooterBanner'
import PageMeta from '../components/PageMeta/PageMeta'
import { type IPostQueryData } from '../graphql/queries/PostQuery'
import Leaderboard from '../components/Banners/Leaderboard/Leaderboard'
import MPUBanner from '../components/Banners/MPUBanner/MPUBanner'

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      post(id: $id) {
        databaseId
        title
        commentCount
        content
        dateGmt
        link
        primaryCategory
        primaryCategoryURI
        excerpt(format: RENDERED)
        seo {
          metaDesc
          title
        }
        featuredImage {
          node {
            mediaItemUrl
          }
        }
        comments(first: 0, last: 5) {
          nodes {
            id
            dateGmt
            author {
              node {
                name
              }
            }
            content
          }
        }
        pinAddress
        schoolType {
          category
        }
        schoolPupils
        schoolRemoveRecommendation
        schoolAge
        schoolHead
        schoolGender
        categories(first: 100) {
          nodes {
            id
            name
            parentId
            slug
            uri
          }
        }
        recommendedArticles {
          link
          title
          image
          category
          categoryURI
        }
      }
      generalSettings {
        title
        siteId
        egSettingTwitter
      }
      adStarCodes {
        id
        adslot
      }
    }
  }
`

interface PostProps {
  data: IPostQueryData
}

const PostTemplate = ({
  data: {
    wpgraphql: { post, generalSettings, adStarCodes }
  }
}: PostProps) => {
  const { siteId, title } = generalSettings
  const isBPTL = post.categories?.nodes?.some(
    category => category?.name === 'Best Places to Live'
  )

  const adsToShow = adStarCodes.filter(
    ad =>
      ad?.adslot?.toLowerCase().includes('mpu') ||
      ad?.adslot?.toLowerCase().includes('lb')
  )

  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: post.seo?.title ?? post.title,
          description:
            post.seo?.metaDesc ?? post.excerpt?.replace(/(<([^>]+)>)/gi, ''),
          image: post.featuredImage?.node?.mediaItemUrl || null,
          url: post.link
        }}
      />
      <Header siteId={siteId} siteName={title} />
      {post ? (
        <>
          <TopBanner
            ads={adsToShow}
            showMPUs
            {...(post?.primaryCategory && { category: post.primaryCategory })}
            pageType={isBPTL ? `bptl` : `feature`}
          />
          <Leaderboard adSlot={'TopLB'} sticky />
          <Article post={post} siteId={siteId || 1} />
          <MPUBanner
            adSlots={['Row1MPU1', 'Row1MPU2', 'Row1MPU3', 'Row1MPU4']}
          />
          {isBPTL && <FooterBanner />}
        </>
      ) : (
        <>Not found</>
      )}
      <Footer />
    </>
  )
}

export default PostTemplate
