// extracted by mini-css-extract-plugin
export var BPTLNumber = "Block-module--BPTLNumber--18d83";
export var Base = "Block-module--Base--d0c7f";
export var Block_Text = "Block-module--Block_Text--f9ea7";
export var Category = "Block-module--Category--e6d18";
export var DarkMode = "Block-module--DarkMode--10cb5";
export var Favourites = "Block-module--Favourites--bf93d";
export var HasSpotlight = "Block-module--HasSpotlight--3fdac";
export var Img = "Block-module--Img--0f85a";
export var Shader = "Block-module--Shader--49c21";
export var SpotlightOverlay = "Block-module--SpotlightOverlay--db3f0";
export var Text = "Block-module--Text--d496a";
export var Title = "Block-module--Title--d4202";
export var fadeOut = "Block-module--fadeOut--8b523";