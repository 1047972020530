import React, { useEffect, useState, useRef } from 'react'
import * as MPUBannerStyles from './MPUBanner.module.scss'
import classNames from 'classnames'

interface MPUBannerProps {
  order?: number
  adSlots: Array<string>
  hasDMPU?: boolean
  sticky?: boolean
}

const MPUBanner = ({ order, adSlots, hasDMPU, sticky }: MPUBannerProps) => {
  const [adsToShow, setAdsToShow] = useState<string[]>([])
  const [adition, setAdition] = useState<any>(null)
  const [adLoaded, setAdLoaded] = useState<boolean>(false)

  useEffect(() => {
    if (adition && adition !== null) {
      // eslint-disable-next-line
    // @ts-ignore
      const qded = adition
      const isMobile = window.matchMedia('(max-width: 768px)').matches
      const withDMPU = isMobile ? adSlots.slice(0, 1) : adSlots.slice(1)
      const withoutDMPU = isMobile
        ? adSlots.filter(ad => ad !== 'DMPU').slice(0, 1)
        : adSlots.filter(ad => ad !== 'DMPU')
      const ads = hasDMPU ? withDMPU : withoutDMPU

      setAdsToShow(ads)

      qded?.srq.push(function (api: any) {
        ads.forEach(slot => {
          api.renderSlot(slot)
        })
      })
    }
  }, [adition])

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null

    const checkAdition = () => {
      if (typeof window !== 'undefined' && 'adition' in window) {
        if ('ready' in window.adition) {
          setAdition(window.adition)
          if (intervalId) {
            setAdLoaded(true)
            clearInterval(intervalId) // Clear the interval if it has been set
          }
        }
      }
    }

    intervalId = setInterval(checkAdition, 1000) // Check every second

    return () => {
      // Clean up on component unmount
      if (intervalId) clearInterval(intervalId)
    }
  }, [])

  const elementRef = useRef<HTMLDivElement>(null)
  const [shouldShowClass, setShouldShowClass] = useState<boolean>(false)
  const [stickyFinished, setStickyFinished] = useState<boolean>(false)

  useEffect(() => {
    if (!elementRef.current) return
    if (!sticky) return
    if (!adLoaded) return

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !stickyFinished) {
          setShouldShowClass(true)
          setTimeout(() => {
            setShouldShowClass(false)
            setTimeout(() => {
              setStickyFinished(true)
              if (elementRef.current) observer.unobserve(elementRef.current)
            }, 1000)
          }, 3000) // remove class after 3 seconds
        }
      },
      {
        threshold: 1.0 // element is fully visible
      }
    )

    observer.observe(elementRef.current)

    // Cleanup
    return () => {
      if (elementRef.current) observer.unobserve(elementRef.current)
    }
  }, [adLoaded])

  return (
    <div
      className={classNames({
        [MPUBannerStyles.Wrapper]: true,
        [MPUBannerStyles.MPU]: sticky,
        [MPUBannerStyles.Sticking]:
          sticky && shouldShowClass && !stickyFinished,
        [MPUBannerStyles.Unstuck]: sticky && !shouldShowClass && !stickyFinished
      })}
      ref={elementRef}
      style={{ '--order': order } as React.CSSProperties}
    >
      <div className={`${MPUBannerStyles.AdBlocks}`}>
        {adsToShow.map((ad, index) => (
          <div id={ad} key={index}></div>
        ))}
      </div>
    </div>
  )
}

export default MPUBanner
