import React, { useContext, useEffect, useState } from 'react'
import * as ArticleStyles from './Article.module.scss'
import * as ArchiveStyles from '../../Archive/Archive.module.scss'
import Hr from '../../Hr/Hr'
import Breadcrumb from '../../organisms/breadcrumb/Breadcrumb'
import Logomark from '../../Logomark/Logomark'
import ArticleShare from '../../ArticleShare/ArticleShare'
import RecommendedArticles from '../../organisms/recommendedArticles/RecommendedArticles'
import { BPTLContext, BPTLStates, formatDate } from '../../../lib/utils'
import { Post } from '../../../types'
import Favourites from '../../Favourites/Favourites'
import Facebook from '../../../assets/facebook.svg'
import Instagram from '../../../assets/instagram.svg'
import * as BreadcrumbStyles from '../../organisms/breadcrumb/Breadcrumb.module.scss'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import parse, { Element, DOMNode, domToReact } from 'html-react-parser'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import BPTLButton from '../../../components/BPTLButton/BPTLButton'
import Leaderboard from '../../../components/Banners/Leaderboard/Leaderboard'

interface ArticleProps {
  post: Post
  siteId?: number
}

const Article = ({ post, siteId }: ArticleProps) => {
  const bptlState = useContext(BPTLContext)
  const Categories = post.categories?.nodes
  const CommentsArray = post.comments?.nodes
  const primaryCategory = {
    title: post.primaryCategory ?? null,
    URI: post.primaryCategoryURI ?? null
  }
  const isSchoolReview = Categories?.some(
    category => category?.slug === 'school-reviews'
  )
  const isBestPlacestoLive = Categories?.some(
    category => category?.slug === 'best-places-to-live'
  )
  const isTurquoise = Categories?.some(
    category => category?.slug === 'turquoise'
  )
  const latLong = post.pinAddress
  const [currentVisiblePopup, setCurrentVisiblePopup] = useState<
    string | undefined
  >(undefined)
  const [showComments, setShowComments] = useState<boolean>(false)
  const [showMap, setShowMap] = useState<boolean>(false)
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [parsedContent, setParsedContent] = useState<any>('')

  const domNodeIsElement = (domNode: DOMNode): domNode is Element => {
    return domNode.type === 'tag'
  }
  useEffect(() => {
    if (post && post.content) {
      const parsed = parse(post.content, {
        replace: domNode => {
          if (!domNodeIsElement(domNode)) {
            return
          }

          if (domNode.name === 'a') {
            const href = domNode.attribs.href
            const linkType = href
              ? href.indexOf('muddystilettos.co.uk') > -1
                ? 'internal'
                : href.indexOf('http') > -1
                ? 'external'
                : 'internal'
              : 'internal'
            if (linkType === 'internal') {
              return (
                <Link
                  to={href}
                  className={domNode.attribs.className}
                  target="_SELF"
                >
                  {domToReact(domNode.children)}
                </Link>
              )
            } else {
              return (
                <OutboundLink
                  href={href}
                  className={domNode.attribs.className}
                  target={domNode.attribs.target}
                >
                  {domToReact(domNode.children)}
                </OutboundLink>
              )
            }
          }
        }
      })
      setParsedContent(parsed)
    }
  }, [post])

  return (
    <article>
      <div className={ArticleStyles.Wrapper}>
        {isBestPlacestoLive && (
          <div className={BreadcrumbStyles.Base}>
            <a href="https://muddystilettos.co.uk/best-places-to-live">
              Best Places To Live
            </a>
            {siteId !== 1 && primaryCategory && (
              <Link to={post.primaryCategoryURI ?? ''}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: post.primaryCategory ?? ''
                  }}
                />
              </Link>
            )}
            {siteId === 1 && (
              <Link to={`/best-places-to-live/best-of-the-rest/`}>
                <span>Best of the Rest</span>
              </Link>
            )}
          </div>
        )}
        {!isBestPlacestoLive && Categories && (
          <Breadcrumb
            categories={Categories}
            primaryCategory={primaryCategory}
          />
        )}
        <div className={ArticleStyles.Intro}>
          {/* {primaryCategory && primaryCategory.title === 'BEAUTY' && (
            <div className={ArticleStyles.SponsoredBy}>
              <p>In association with</p>
              <img
                src={
                  post.databaseId === 77513
                    ? ''
                    : 'http://dev.muddystilettos.co.uk/wp-content/uploads/2023/06/BEAUTY-PIE-LOGO-FINAL_WITH-TM-1.png'
                }
              />
            </div>
          )} */}
          <h1 dangerouslySetInnerHTML={{ __html: post.title ?? '' }} />
          <span
            dangerouslySetInnerHTML={{
              __html: post.excerpt ?? ''
            }}
          />
          {primaryCategory &&
            primaryCategory.title === 'PARENTING' &&
            post.databaseId === 94307 && (
              <div className={ArchiveStyles.SponsoredBy}>
                <p>In association with</p>

                <a
                  href={`https://www.startriteshoes.com?utm_campaign=schoolcampaign&utm_medium=referral&utm_source=muddystilettoes`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <StaticImage
                    width={300}
                    src={`../../../static/images/sponsors/sr-black.png`}
                    alt={`Start-Rite`}
                  />
                </a>
              </div>
            )}
          {isBestPlacestoLive && (
            <>
              <div className={ArticleStyles.BPTLlogo}>
                <p>In association with</p>
                <div>
                  <StaticImage
                    className={ArticleStyles.BPTLlogoImage}
                    src={`../../../../static/images/best-places-to-live/kf-brandmark-red.png`}
                    alt={`Knight Frank`}
                    height={74}
                    width={200}
                  />
                </div>
              </div>

              {bptlState === BPTLStates.REGIONAL && (
                <>
                  <p>
                    Vote for {post?.title?.replace('Best Places to Live: ', '')}{' '}
                    in our {primaryCategory.title}{' '}
                    <Link to={primaryCategory.URI ?? '/best-places-to-live/'}>
                      Best Places to Live Readers Vote
                    </Link>
                    :
                  </p>
                  <div className={ArticleStyles.BPTLWrapper}>
                    <BPTLButton
                      post={{
                        ...post,
                        id: post.databaseId,
                        spotlightDesktopImage:
                          post.featuredImage?.node?.mediaItemUrl
                      }}
                      setPopup={setCurrentVisiblePopup}
                      popupVisible={currentVisiblePopup ? true : false}
                      catName={primaryCategory.title ?? ''}
                      siteId={siteId ?? 1}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div className={ArticleStyles.Main}>
          <div className={ArticleStyles.Meta}>
            <span className={ArticleStyles.Date}>
              {post.dateGmt ? formatDate(post.dateGmt) : ''}
            </span>
            <div className={ArticleStyles.Share}>
              <span className={ArticleStyles.ShareText}>Share this story:</span>{' '}
              <span className={ArticleStyles.SocialWrap}>
                <ArticleShare postLink={post.link ?? ''} />
              </span>
              <Favourites
                siteId={siteId?.toString()}
                postId={post.databaseId.toString()}
              />
            </div>
          </div>
          {isBestPlacestoLive && post.featuredImage && (
            <div className={ArticleStyles.FeaturedImage}>
              <img src={post.featuredImage.node?.mediaItemUrl || ''} />
            </div>
          )}
          {isSchoolReview && (
            <div className={ArticleStyles.AtAGlance}>
              <div
                className={ArticleStyles.SchoolDetails}
                onClick={() => setShowDetails(!showDetails)}
              >
                {showDetails ? 'Close details at a glance' : 'Open at a glance'}
              </div>
              {showDetails && (
                <div className={ArticleStyles.SchoolReviewBlock}>
                  <div className={ArticleStyles.SchoolReviewCol}>
                    <p className={ArticleStyles.SchoolReviewColParagraph}>
                      <strong>Pupils: </strong>
                      {post.schoolPupils}
                      <br />
                      {post.schoolGender && post.schoolGender.length > 0 && (
                        <>
                          <strong>Gender: </strong>
                          {post.schoolGender.join(', ')}
                        </>
                      )}
                      <br />
                      <strong>Age: </strong>
                      {post.schoolAge}
                      <br />
                    </p>
                  </div>
                  <div className={ArticleStyles.SchoolReviewCol}>
                    <p>
                      <strong>Boarding: </strong>
                      {post.schoolType?.some(
                        type => type?.category === 'boarding-school'
                      )
                        ? 'Yes'
                        : 'No'}
                      <br />
                      <strong>Head: </strong>
                      {post.schoolHead}
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
          {isBestPlacestoLive && (
            <>
              <div
                className={ArticleStyles.CommentsTag}
                onClick={() => setShowMap(!showMap)}
              >
                {showMap ? 'Hide' : 'Show'} Map
              </div>
              {showMap && (
                <div className={ArticleStyles.BPTLMap}>
                  <div className={ArticleStyles.GoogleMap}>
                    <iframe
                      width="820"
                      height="420"
                      loading="lazy"
                      allowFullScreen
                      referrerPolicy="no-referrer-when-downgrade"
                      src={`https://www.google.com/maps/embed/v1/view?key=AIzaSyDySDPxJPrZ-9x46IaAcvbBXTSyhY7bAxc&zoom=12&center=${latLong}`}
                    ></iframe>
                  </div>
                </div>
              )}
            </>
          )}
          {isTurquoise && (
            <div
              style={{ textAlign: 'center' }}
              className={ArticleStyles.TurquoiseSponsor}
            >
              <p style={{ marginBottom: '0' }}>In association with</p>
              <a
                href={`https://www.turquoiseholidays.co.uk/special-offers/summer-school-holiday-offers/?utm_source=muddy-stilettos&utm_medium=holiday-guides&utm_campaign=muddy-stilettos-holiday-guides`}
                target="_BLANK"
                rel="noreferrer"
              >
                <StaticImage
                  className={ArticleStyles.TurquoiseImage}
                  width={300}
                  height={100}
                  src={`../../../../static/images/sponsors/turquoise.jpg`}
                  alt={`Turquoise`}
                />
              </a>
            </div>
          )}
          <div className={ArticleStyles.Content}>{parsedContent}</div>
          {isSchoolReview && !post.schoolRemoveRecommendation && (
            <div className={ArticleStyles.Badge}>
              <StaticImage
                src={`../../../../static/badges/best-schools-guide-2024.png`}
                alt={`Recommended by Best Schools Guide Badge`}
              />
            </div>
          )}
          {post.schoolInstagram && post.schoolFacebook && (
            <div className={ArticleStyles.SchoolSocialLinks}>
              <a href={post.schoolFacebook} target="_blank" rel="noreferrer">
                <img src={Facebook} alt={`Pin`} />
              </a>
              <a href={post.schoolInstagram} target="_blank" rel="noreferrer">
                <img src={Instagram} alt={`Pin`} />
              </a>
            </div>
          )}
          {latLong && !isBestPlacestoLive && (
            <>
              <div className={ArticleStyles.GoogleMap}>
                <iframe
                  width="820"
                  height="420"
                  loading="lazy"
                  allowFullScreen
                  referrerPolicy="no-referrer-when-downgrade"
                  src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDySDPxJPrZ-9x46IaAcvbBXTSyhY7bAxc&q=${latLong}`}
                ></iframe>
              </div>
              <p>
                Open in{' '}
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${latLong}`}
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  Google Maps
                </a>
              </p>
            </>
          )}

          {isBestPlacestoLive && (
            <>
              {bptlState === BPTLStates.REGIONAL && (
                <>
                  <div className={ArticleStyles.Content}>
                    <p>
                      Vote for{' '}
                      <strong>
                        {post?.title?.replace('Best Places to Live: ', '')}
                      </strong>{' '}
                      in our{' '}
                      <Link to={post.primaryCategoryURI ?? ''}>
                        Best Places to Live Readers Vote
                      </Link>
                      :
                    </p>
                    <div className={ArticleStyles.BPTLWrapper}>
                      <BPTLButton
                        post={{
                          ...post,
                          id: post.databaseId,
                          spotlightDesktopImage:
                            post.featuredImage?.node?.mediaItemUrl
                        }}
                        setPopup={setCurrentVisiblePopup}
                        popupVisible={currentVisiblePopup ? true : false}
                        catName={primaryCategory.title ?? ''}
                        siteId={siteId ?? 1}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          <Logomark />
          <div
            className={ArticleStyles.CommentsTag}
            onClick={() => setShowComments(!showComments)}
          >
            {CommentsArray?.length === 0
              ? `Be the first to comment`
              : showComments
              ? 'Hide comments'
              : 'Show comments'}
          </div>
          <div className={ArticleStyles.Comments}>
            {CommentsArray &&
              showComments &&
              CommentsArray.map(comment => (
                <div className={ArticleStyles.CommentBlock} key={comment?.id}>
                  <div className={ArticleStyles.CommentText}>
                    <p>
                      <strong>{comment?.author?.node?.name}</strong>{' '}
                      <span className={ArticleStyles.CommentDate}>
                        {post.dateGmt ? formatDate(post.dateGmt) : ''}
                      </span>
                      <br />
                      <span
                        dangerouslySetInnerHTML={{
                          __html: comment?.content ?? ''
                        }}
                      />
                    </p>
                  </div>
                </div>
              ))}
            {showComments && (
              <div className={ArticleStyles.CommentForm}>
                <p className={ArticleStyles.FormTitle}>
                  Tell us what you think
                </p>
                <p
                  className={`${ArticleStyles.FormRow} ${ArticleStyles.FullWidth}`}
                >
                  <label>Your message</label>
                  <textarea rows={4} />
                </p>
                <p className={ArticleStyles.FormRow}>
                  <label>Name (required)</label>
                  <input type="text" />
                </p>
                <p className={ArticleStyles.FormRow}>
                  <label>Email address (required)</label>
                  <input type="text" />
                </p>
                <p className={ArticleStyles.ButtonRow}>
                  <button type="submit">Sign in or sign up to comment</button>
                </p>
              </div>
            )}
          </div>
        </div>
        <Leaderboard sticky adSlot={'MidLB1'} />
        <div className={`${ArticleStyles.Share} ${ArticleStyles.ExtraSpace}`}>
          <span className={ArticleStyles.ShareText}>Share this story:</span>{' '}
          <span className={ArticleStyles.SocialWrap}>
            <ArticleShare postLink={post.uri ?? ''} />
          </span>
        </div>
      </div>
      <Hr />
      {post?.recommendedArticles && (
        <RecommendedArticles
          category={post.primaryCategory}
          recommendedArticles={post?.recommendedArticles}
          isBestPlacestoLive={isBestPlacestoLive}
          siteId={siteId}
        />
      )}
    </article>
  )
}

export default Article
